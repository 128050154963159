import { useState, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  useTheme,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { DynamicInput, IconButton } from 'src/components';
import { useLogging, useNotification } from 'src/context';
import { useGetUserInputOptions } from 'src/hooks';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { green } from '@mui/material/colors';
import useTestPushToCRM from 'src/hooks/integrations/useTestPushToCRM';

// FLOW 3: One mapping, one or more options - Dialog Steps
// FLOW 4: Multiple mappings, 0 or more options - Dialog, then based on mapping, either submit or show the flow
interface TestPushToCRMDialogProps extends DialogProps {
  mapping: MedScout.Mapping;
  isDryRun: boolean;
  handleCRMTestDialogResponse: (response: any, isDryRun: boolean) => void;
  setTestPushError: (error: string) => void;
}
const TestPushToCRMDialog = ({
  open,
  onClose,
  mapping,
  isDryRun,
  handleCRMTestDialogResponse,
  setTestPushError,
}: TestPushToCRMDialogProps) => {
  const theme = useTheme();
  const log = useLogging();
  const { setNotification } = useNotification();

  const { mutateAsync: testPushToCRM, isLoading } = useTestPushToCRM();

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const { data: userInputOptions } = useGetUserInputOptions({
    mappingId: mapping?.id,
    page: 0,
    pageSize: 100,
  });

  // Determine all options are hidden
  const isAllHidden = userInputOptions?.results?.every(
    (option) => option?.hidden === true
  );

  const handleCancel = (e) => {
    reset();
    onClose(e, 'backdropClick');
  };

  const onSubmit = async (data: any, e) => {
    try {
      // We need to include the hidden fields in the payload if isAllHidden is true
      const fieldsToPush = isAllHidden
        ? userInputOptions?.results?.reduce((acc, option) => {
            if (!option?.hidden) return acc;
            acc[option?.crm_api_code] = option?.extra?.default_value || '';
            return acc;
          }, {})
        : data;

      const input = {
        data: { mapping: mapping?.id, fields: fieldsToPush },
        isDryRun,
      };

      const response = await testPushToCRM(input);
      handleCRMTestDialogResponse(response, isDryRun);

      if (!isDryRun) {
        //TO DO  move notification function into shared file?
        setNotification({
          title: 'Test Push to CRM successful',
          message: 'Successfully tested Push to CRM.',
          type: 'success',
        });
      }
      handleCancel(e);
      log.event('Test Push to CRM', {
        mapping,
        isDryRun,
      });
    } catch (err: any) {
      setNotification({
        title: 'Test Push to CRM failed',
        message: 'Error testing Push to CRM, please check config.',
        type: 'error',
      });

      log.exception(`Error testing Push to CRM: ${err}`);
      setTestPushError(err.cause.response.data[0].message);
      handleCancel(e);
    }
  };

  // TO DO move this function to another file to share between test and reg.
  // move all Boolean options to the end
  const newOptions = useMemo(() => {
    if (!userInputOptions?.results) return [];
    return userInputOptions.results?.sort((opt) =>
      opt.input_type === 'BOOLEAN' ? 1 : -1
    );
  }, [userInputOptions?.results]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'}>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          Test Push to CRM
          <IconButton
            icon={faTimes}
            onClick={(e) => onClose(e, 'backdropClick')}
            style={{ color: theme.palette.grey[500] }}
            size="small"
          />
        </Box>
        <Typography variant="h6" sx={{ alignSelf: 'flex-start' }}>
          This is what the team will be asked to complete when adding a new
          object to the selected CRM mapping.
        </Typography>
      </DialogTitle>
      {/* TO DO dialog content component?  */}
      <DialogContent>
        {mapping && (
          <Box
            sx={{
              mb: 2,
              pb: 1,
              borderBottom: `1px solid ${theme.palette.grey[200]}`,
            }}
          >
            <Typography variant="h6">{mapping?.label}</Typography>
          </Box>
        )}
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          {newOptions
            ?.filter((o) => o.input_type !== 'CRM_LOOKUP')
            .map((option, index) => {
              return (
                <Grid
                  key={index}
                  item
                  sm={12}
                  justifyContent="flex-end"
                  sx={{
                    display: option?.hidden ? 'none' : 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {option?.input_type !== 'BOOLEAN' && (
                    <Typography
                      sx={{
                        fontSize: '0.8rem',
                        fontWeight: 600,
                        color: theme.palette.grey[700],
                        gap: '0.5rem',
                        alignItems: 'center',
                        display: option?.hidden ? 'none' : 'flex',
                      }}
                    >
                      {option?.label} (required)
                    </Typography>
                  )}
                  <DynamicInput
                    type={option.input_type?.toLowerCase()}
                    name={option.crm_api_code?.toLowerCase()}
                    rules={{
                      required: option?.extra?.required || false,
                    }}
                    hidden={option?.hidden}
                    control={control}
                    variant="standard"
                    value={option?.extra?.default_value}
                    placeholder={
                      `${option?.extra?.default_value}` || 'e.g. 1234'
                    }
                    options={option?.extra?.options || []}
                    label={option?.label}
                  />
                </Grid>
              );
            })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0.5rem 1rem',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Button
              variant="outlined"
              onClick={(e) => {
                handleCancel(e);
                log.event('Test Push to CRM - Cancel', {
                  mapping,
                });
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
              sx={{
                background: green[800],
                '&:hover': { background: green[900] },
              }}
              disabled={!isValid}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default TestPushToCRMDialog;
