import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { useQuery } from 'react-query';
import { request } from '../request';

const QUERY_KEYS = {
  status_list: 'status_list',
  data_sources_info: 'data_sources_info',
};

export function useGetDataSourcesInfo(enabled) {
  return useQuery(
    [QUERY_KEYS.data_sources_info],
    () => {
      const queryParams = getSearchQueryParamsForAPI({});

      return request({
        url: `/v1/meta/data-sources${queryParams}`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: Boolean(enabled),
      refetchOnWindowFocus: false,
      cacheTime: 600000,
    }
  );
}
